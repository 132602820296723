<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_crmnote" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{ lang.add_notes }}</span>
        </div>
        <div @click="hide" id="hideNotes" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{ lang.close }}</span>
      </div>
    </div>
        <v-divider></v-divider>
        <v-row style="direction:rtl" class="m-2">
            <v-col cols="12" md="12" sm="12">
                <label>{{lang.notes}}</label>
                <b-form-textarea class="inborder"
                    v-model="notes"
                    value=""
                    required
                    ></b-form-textarea>
            </v-col>
        </v-row>
    </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addNote()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            customerid:0,
            notes: '',
            
        }
    },
    methods: {
        addNote(){
            if(this.notes == ''){
                let message = this.lang.note_text_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addNoteToClient");
            post.append('defBranch',localStorage.getItem('defBranch'));
             post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customerid]",this.customerid);
            post.append("data[notes]",this.notes);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = this.lang.added_successfuly;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getCustomerInfo();
                document.getElementById('hideNotes').click();
            });
            
            this.message = '';
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    created() {
        //this.getNotes();
    },
}
</script>